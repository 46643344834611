import { ChangeEvent, createRef, useEffect } from "react";
import { formatString } from "@format-string/core";
import { Grid } from "@mui/material";
import { usePageControle } from "src/Contexts/ControlCompanies/ControleEmpresaContext";
import { ICreateRegister } from "src/Contexts/types/ProdutoresContext.Types";
import { AppTextField } from "src/Pages/components";
import { useStylesInput } from "src/Pages/TelasLogin/Login/components/FormLogin";

export const FormLoginRegister = () => {
  const { valuesInputsRegister, setValuesInputsRegister } = usePageControle();

  const classes = useStylesInput();

  const InputNomeRef = createRef<HTMLInputElement>();

  useEffect(() => {
    InputNomeRef.current?.focus();
  }, []);

  const handleChange =
    (prop: keyof ICreateRegister, option?: "phone") =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (option === "phone") {
        setValuesInputsRegister((prevValue) => ({
          ...prevValue,
          [prop]: formatString({ value: event.target.value, type: "phone" }),
        }));
      } else {
        setValuesInputsRegister((eventPrev) => ({
          ...eventPrev,
          [prop]: event.target.value,
        }));
      }
    };

  return (
    <Grid container spacing={1} sx={{ marginBottom: "1rem" }}>
      <Grid item xs={12}>
        <AppTextField
          fullWidth
          InputProps={{ className: classes.input }}
          inputRef={InputNomeRef}
          label="Nome completo *"
          name="Nome"
          sxStyle={{ opacity: "", pointerEvents: "" }}
          value={valuesInputsRegister.access_name}
          variant="outlined"
          onChange={handleChange("access_name")}
        />
      </Grid>

      <Grid item xs={12}>
        <AppTextField
          fullWidth
          InputProps={{ className: classes.input }}
          label="Email *"
          name="email"
          sxStyle={{ opacity: "", pointerEvents: "" }}
          value={valuesInputsRegister.access_email}
          variant="outlined"
          onChange={handleChange("access_email")}
        />
      </Grid>

      <Grid item xs={12}>
        <AppTextField
          fullWidth
          InputProps={{ className: classes.input }}
          label="Senha *"
          name="senha"
          sxStyle={{
            opacity: "",
            pointerEvents: "",
          }}
          value={valuesInputsRegister.access_password}
          variant="outlined"
          onChange={handleChange("access_password")}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <AppTextField
          fullWidth
          InputProps={{ className: classes.input }}
          label="Nome da empresa *"
          name="empresa"
          sxStyle={{
            opacity: "",
            pointerEvents: "",
          }}
          value={valuesInputsRegister.nome_empresa}
          variant="outlined"
          onChange={handleChange("nome_empresa")}
        />
      </Grid>

      <Grid item md={6} xs={12}>
        <AppTextField
          fullWidth
          InputProps={{ className: classes.input }}
          label="Telefone *"
          name="empresa"
          sxStyle={{
            opacity: "",
            pointerEvents: "",
          }}
          value={valuesInputsRegister.telefone_1}
          variant="outlined"
          onChange={handleChange("telefone_1", "phone")}
        />
      </Grid>
    </Grid>
  );
};
