import { useState } from "react";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useContextViews } from "src/Contexts/Views/contextViews";
import {
  AssuredWorkloadOutlinedIcon,
  AttachMoneyIcon,
  DescriptionIcon,
  FileOpenIcon,
  PercentIcon,
  SensorOccupiedIcon,
  SupervisedUserCircleIcon,
} from "src/Pages/components/Icons/Icons";
import { UrlConfig } from "src/shared/Utils/paths";

import { AsideMenuLeft } from "./AsideMenuLeft";
import { BaseMenuAside } from "./componentes/BaseMenuAside";

export const AsideTitle = () => {
  const {
    NavigationControleEmpresa,
    NavigationDashboard,
    NavigationLancamentos,
    NavigationPagamentos,
    NavigationRelations,
    NavigationProdutores,
    NavigationComissoes,
    NavigationUsuarios,
  } = useContextViews();

  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);

  return (
    <>
      <AsideMenuLeft
        icon={<AutoAwesomeMosaicIcon />}
        title="Inicio"
        to={UrlConfig.home}
      />

      <BaseMenuAside
        Navigation={NavigationDashboard}
        icon={<BarChartIcon />}
        open={open9}
        setOpen={setOpen9}
        title={UrlConfig.dashboard.title}
      />
      <BaseMenuAside
        Navigation={NavigationControleEmpresa}
        icon={<AssuredWorkloadOutlinedIcon />}
        open={open4}
        setOpen={setOpen4}
        title={UrlConfig.controle.title}
      />
      <BaseMenuAside
        Navigation={NavigationUsuarios}
        icon={<SupervisedUserCircleIcon />}
        open={open3}
        setOpen={setOpen3}
        title={UrlConfig.usuarios.title}
      />
      <BaseMenuAside
        Navigation={NavigationProdutores}
        icon={<SensorOccupiedIcon />}
        open={open1}
        setOpen={setOpen1}
        title={UrlConfig.produtores.title}
      />
      <BaseMenuAside
        Navigation={NavigationComissoes}
        icon={<PercentIcon />}
        open={open5}
        setOpen={setOpen5}
        title={UrlConfig.comissao.title}
      />
      <BaseMenuAside
        Navigation={NavigationLancamentos}
        icon={<FileOpenIcon />}
        open={open6}
        setOpen={setOpen6}
        title={UrlConfig.lancamentos.title}
      />
      <BaseMenuAside
        Navigation={NavigationPagamentos}
        icon={<AttachMoneyIcon />}
        open={open7}
        setOpen={setOpen7}
        title={UrlConfig.pagamentos.title}
      />
      <BaseMenuAside
        Navigation={NavigationRelations}
        icon={<DescriptionIcon />}
        open={open8}
        setOpen={setOpen8}
        title={UrlConfig.relatoriosProducao.title}
      />
    </>
  );
};
