import { GetDateUSSemValue } from "src/shared/Utils/FormatDateBR";

import { IGetRecibos } from "../RecibosTypes";

export const valuesDefaultInputsRecibos: IGetRecibos = {
  type_payments: "ALL",
  start_date: `${GetDateUSSemValue()}`,
  end_date: `${GetDateUSSemValue()}`,
  isGrupoImposto: false,
  isImpostoGlobal: true,

  funcao_produtor: "CORRETOR",

  numeroParcelas: "ALL",

  operadoras: [],

  contaCorrente: false,
  parcelas: false,
  visualizar: true,
};
